import { SessionProvider } from 'next-auth/react'
import { Session } from 'next-auth'
import { FC, useState } from 'react'
import fetcher from 'fetcher'
import { SWRConfig } from 'swr'
import { CNOptions, NotificationContext } from 'mad-mint/contexts/Notification'
import '../styles/global.css'
import Notifier from 'mad-mint/components/Notifier'
import AdminAuthGuard from 'mad-mint/components/Authentication/admin'
import CreatorAuthGuard from 'mad-mint/components/Authentication/creator'
import AuthGuard from 'mad-mint/components/Authentication/registered'
import Head from 'next/head'
import Script from 'next/script'

type AppProps = {
  Component: FC & {
    requireAuth?: boolean
    requireAdmin?: boolean
    requireCreator?: boolean
  }
  pageProps: {
    session: Session | null
  }
}

const swrConfigOpts = {
  fetcher,
}

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps) {
  const notificationState = useState<CNOptions>(null)

  return (
    <SessionProvider session={session}>
      <Head>
        <link rel="shortcut icon" href="/images/favicon.ico" />
        <link
          rel="preload"
          href="/fonts/Poppins-Bold.ttf"
          as="font"
          crossOrigin=""
        />
      </Head>
      <SWRConfig value={swrConfigOpts}>
        <NotificationContext.Provider value={notificationState}>
          {Component.requireAdmin ? (
            <AdminAuthGuard>
              <Component {...pageProps} />
            </AdminAuthGuard>
          ) : Component.requireCreator ? (
            <CreatorAuthGuard>
              <Component {...pageProps} />
            </CreatorAuthGuard>
          ) : Component.requireAuth ? (
            <AuthGuard>
              <Component {...pageProps} />
            </AuthGuard>
          ) : (
            <Component {...pageProps} />
          )}

          <Notifier />
        </NotificationContext.Provider>
      </SWRConfig>
    </SessionProvider>
  )
}
