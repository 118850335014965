import React, { ReactNode } from 'react'
import Header from './Header'
import Footer from './Footer'
import { signIn, useSession, signOut } from 'next-auth/react'
import useDetectWindowMode, {
  WINDOW_MODE,
} from '../../hooks/useDetectWindowMode'
import { useRouter } from 'next/router'

type Props = {
  children: ReactNode
  className?: string
  page: string
}

const DP_BG: any = {
  login: 'login',
  landing: 'landing',
  project: 'project',
}

const Layout: React.FC<Props> = ({ children, className, page }) => {
  const router = useRouter()
  const windowMode = useDetectWindowMode()
  const isMobile = [
    WINDOW_MODE['XSM'],
    WINDOW_MODE['SM'],
    WINDOW_MODE['MD'],
  ].includes(windowMode)

    return (
        <div className="md:flex md:min-h-screen font-jost-regular">
            <div className="content-view flex mx-auto w-full md:min-h-screen" style={
                    {
                        backgroundImage: router.pathname === '/' ? "url('/images/BSC/index_bg.png')" : (router.pathname === '/login' ? "url('/images/BSC/login_bg.png')" : "url('/images/BSC/globe_bg.png')"),
                        backgroundSize: "auto 100%",
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center right'
                    }
                }>
                {/* <div className='hidden md:block lg:hidden fixed top-0 left-0 w-full h-full bg-black/50' style={
                    {
                        zIndex: 1
                    }
                }></div> */}
                <div className="relative flex flex-col md:min-h-screen justify-between md:basis-3/5 md:pt-[38px] md:pb-6 md:px-10 lg:px-20 2xl:pl-[123px] 2xl:pr-[50px] w-full" style={
                    {
                        zIndex: 2
                    }
                }>
                    <div className='hidden md:block absolute w-[120%] 2xl:w-full h-full top-0 left-0' style={
                        {
                            background: 'linear-gradient(90deg, rgba(0, 0, 0, 0.9) 80%, rgba(0, 0, 0, 0) 99.93%)',
                            zIndex: 0,
                            width: (router.pathname === '/[slug]' || router.pathname === '/dashboard') ? '100%!important' : ''
                        }
                    }></div>
                    <Header />
                    <div className='grid flex-1 md:relative md:content-between overflow-y-auto md:overflow-y-visible md:mt-0 mt-[112px] max-w-[1000px]'>
                        {
                            // router.pathname === '/globe' ? (<div className="hidden md:block"></div>) : (<></>)
                            <div className="hidden md:block"></div>
                        }
                        <div className="grid content-wrapper flex-1 px-5 py-9 md:py-0 md:px-0">
                        {children}
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
      <style jsx global>{`
        body {
          max-height: 100vh;
          background-color: #000105;
        }
        @media(max-width:767px) {
            .content-wrapper {
                background: url('/images/BSC/mb_bg.png') no-repeat center center;
                background-size: cover;
            }
            .content-view {
                background: none!important;
            }
        }
      `}</style>
    </div>
  )
}

export default Layout
