import { signIn, signOut, useSession, getSession } from 'next-auth/react'
import { useEffect, useState } from 'react'
import useSWR from 'swr'
import { useRouter } from 'next/router'
import Layout from '../../layouts/legendofrocks/login'
import Spinner from '../Spinner'

type Props = {
  children: JSX.Element
}

const AuthGuard: React.FC<Props> = ({ children }) => {
  const { data: session, status } = useSession()
  const [loaded, setLoaded] = useState(false)
  const router = useRouter()

  const { data: project } = useSWR(
    session ? `/api/mad-mint/projects/firstproject` : null
  )

  useEffect(() => {
    const securePage = async () => {
      const session = await getSession()
      if (!session) {
        signIn()
      } else if (session && !session.user.id) {
        signOut({ redirect: false }).then(() => {
          router.push('/login')
        })
      } else {
        setLoaded(true)
      }
    }
    securePage()
  }, [])

  if (loaded && session) {
    if (router.pathname === '/dashboard') {
      if (session.user.roles?.includes('admin')) {
        router.push(`/admin`)
      } else if (session.user.roles?.includes('creator')) {
        router.push(`/creator`)
      } else {
        project && router.replace(`/${project.slug}`)
      }
    } else {
      return children
    }
  }

  return (
    <Layout page="project">
      <div className="flex items-center justify-center text-2xl min-h-screen md:min-h-0">
        <div className="spinner">
          <Spinner className="flex white-spinner" />
        </div>
      </div>
      <style jsx global>{`
        .spinner svg {
          height: 80px;
        }
        .white-spinner svg {
          fill: #fff;
        }
      `}</style>
    </Layout>
  )
}

export default AuthGuard
